* {
  -webkit-tap-highlight-color: #0000;
  -webkit-tap-highlight-color: transparent;
}

html, body, #root {
  height: 100%;
}

body {
  --purple: #a561c2;
  --blush: #da739c;
  --tangerine: #f59e87;
  --tan: #f3e3ca;
  --dark: #4a595f;
  color: var(--dark);
  margin: 0;
  font-family: Roboto, sans-serif;
  font-weight: 600;
}

em, strong, b {
  font-family: Merriweather Sans;
  font-style: italic;
  font-weight: 700;
}

h1, h2, h3, h4, h5, h6, h7, h8, h9 {
  color: var(--blush);
  margin-bottom: 18px;
  font-family: Merriweather Sans, sans-serif;
  font-style: italic;
  font-weight: 700;
}

p, ul, ol {
  margin-bottom: 12px;
  font-family: Merriweather;
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
}

p:first-of-type {
  margin-top: 12px;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  color: #0000;
  background: linear-gradient(to right, #f59e87 0%, #da739c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 35px;
  line-height: 1.15;
}

h2 {
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-size: 32px;
  line-height: 40px;
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

h5, h6 {
  font-size: 16px;
  line-height: 24px;
}

figure {
  margin: 0;
}

figcaption {
  margin: 12px 0 0;
  line-height: 30px;
}

ul, ol {
  list-style-position: inside;
}

ul, ol, li {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.wrapped-content, .article > * {
  box-sizing: border-box;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
}

.article h1 {
  text-align: center;
  margin-top: 8vh;
  margin-bottom: 20px;
}

.article img {
  margin: 0 auto;
}

.article p img, .article figure img, iframe {
  max-width: 100%;
}

.article figure {
  text-align: center;
}

.article a {
  color: var(--tangerine);
  text-decoration: underline;
}

.article a:visited {
  color: var(--purple);
}

/*# sourceMappingURL=clientIndex.css.map */
