* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
  height: 100%;
}

body {
  --purple: #a561c2;
  --blush: #da739c;
  --tangerine: #f59e87;
  --tan: #f3e3ca;
  --dark: #4a595f;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  color: var(--dark);
}

em,
strong,
b {
  font-family: 'Merriweather Sans';
  font-style: italic;
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9 {
  font-family: 'Merriweather Sans', sans-serif;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 18px;
  color: var(--blush);
}

p,
ul,
ol {
  font-family: 'Merriweather';
  font-weight: 300;
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 12px;
}
p:first-of-type {
  margin-top: 12px;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  font-size: 35px;
  line-height: 1.15;
  background: linear-gradient(to right, #f59e87 0%, #da739c 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}
h2 {
  font-size: 40px;
  line-height: 48px;
}
h3 {
  font-size: 32px;
  line-height: 40px;
}
h4 {
  font-size: 24px;
  line-height: 32px;
}
h5,
h6 {
  font-size: 16px;
  line-height: 24px;
}

figure {
  margin: 0;
}
figcaption {
  margin: 0;
  margin-top: 12px;
  line-height: 30px;
}
ul,
ol {
  list-style-position: inside;
}
ul,
ol,
li {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.wrapped-content,
.article > * {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 16px;
}

.article h1 {
  margin-top: 8vh;
  margin-bottom: 20px;
  text-align: center;
}

.article img {
  /* display: block; */
  margin: 0 auto;
}

.article p img,
.article figure img,
iframe {
  max-width: 100%;
}
.article figure {
  text-align: center;
}

.article a {
  color: var(--tangerine);
  text-decoration: underline;
}
.article a:visited {
  color: var(--purple);
}
